<template>
  <div class="sm-enricher-view">
    <h1 class="bold">
      Social Media Enricher <br>
      <span>Track companies/ individuals social media performance</span>
    </h1>

    <BaseInput name="emailToSendCSV"
               label="Enter the e-mail address you want to receive the results at"
               placeholder="email address to send csv file"
               :value="emailToSendCSV"
               :required="isCSVFile && invalidEmail"
               @input="handleEmail" />

    <p class="form-title">
      Enter a full name/ email / company domain or upload a CSV
    </p>

    <div class="search-section flex align-center">
      <BaseInput name="name"
                 placeholder="John Smith or jsmith@gmail.com or hubspot.com"
                 :disabled="isCSVFile"
                 :value="form.name"
                 @enter="handleSubmit"
                 @input="handleInput" />
      <span>or</span>
      <BaseFileInput name="csvfile"
                     ref="baseFileRef"
                     label="Upload CSV (with 'full_name' or 'email' or 'domain' column)"
                     @upload="handleFile"
                     @clear="handleClearFile" />
      <button class="purple-btn"
              :disabled="submitDisabled"
              cy="submit"
              @click="handleSubmit">Submit</button>
    </div>

    <div>
      <div v-show="reqStatus === 'sending'" class="m-b-16">
        <BaseLoader />
        <p class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>

      <p v-show="reqStatus === 'failed'" class="text-center m-t-0 m-b-16">
        There was a server error, please try again. <br>
        {{ errorMsg }}
      </p>
    </div>

    <div v-if="result.length"
         cy="results"
         class="result-section m-b-24">
      <h2>Results for {{ searchName }}</h2>
      <ul>
        <li v-for="({name, value}, index) in result"
            :key="index">
          <p>{{ name }}: {{ value }}</p>
        </li>
      </ul>
    </div>

    <div v-if="csvResult"
         cy="csv-results">
      <h2>Results for CSV</h2>
      <p>The csv file was sent to email.</p>
    </div>

    <section class="filters-section"
             cy="filters"
             :class="{active: isFilters}">
      <div class="header inline-flex align-center pointer"
           @click="toggleFilters">
        <h2>Filters</h2>
        <img src="../assets/arrow.svg" alt="filter arrow" />
      </div>

      <BaseInput name="followers_count"
                 placeholder="0"
                 label="Only show results if one of the accounts has at least this minimum number of subscribers"
                 :value="filters.followers_count"
                 @input="handleInputFilter">
        <span>,000</span>
      </BaseInput>

      <BaseCheckbox name="is_apollo_match"
                    :value="filters.is_apollo_match"
                    @click="handleCheckbox"
                    label="Only return results if matched on Apollo.io" />

      <BaseCheckbox name="is_data_valid"
                    :value="filters.is_data_valid"
                    @click="handleCheckbox"
                    label="Only return results if data match between Youtube 'about' and Google SERP results" />

      <button class="purple-btn block"
              cy="save-filters"
              @click="handleSaveFilters">Save settings</button>
    </section>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, ref} from "vue";
  import {useStore} from "vuex";
  import BaseCheckbox from "@/components/BaseCheckbox.vue";
  import {useValidations} from "@/composables/validations";

  const { state, dispatch, commit } = useStore()
  dispatch('get_sm_filters')
  const isFilters = ref(false)
  const reqStatus = computed(() => state.reqStatus)
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const errorMsg = computed(() => state.smEnricher.errorMsg)
  const form = computed(() => state.smEnricher.form)
  const result = computed(() => state.smEnricher.result)
  const csvResult = computed(() => state.smEnricher.csvResult)
  const filters = computed(() => state.smEnricher.filters)
  const searchName = computed(() => state.smEnricher.searchName)
  const isCSVFile = ref(false)
  const submitDisabled = computed(() => reqStatus.value === 'sending' || (!form.value.name && !isCSVFile.value))
  let formData = new FormData()
  const baseFileRef = ref(null)
  const { invalidEmail } = useValidations()

  function handleEmail ({name, value}) {
    commit('SET_EMAIL', value)
  }

  function handleCheckbox ({name, value}) {
    commit('SET_SM_FILTER', {name, value})
  }

  function handleInputFilter ({name, value}) {
    commit('SET_SM_FILTER', {name, value})
  }

  function handleFile ({name, file}) {
    isCSVFile.value = true
    formData = new FormData()
    formData.append(name, file)
    commit('SET_SM_FORM_FIELD', {name: 'name', value: ''})
  }

  function handleClearFile () {
    isCSVFile.value = false
  }

  function toggleFilters () {
    isFilters.value = !isFilters.value
  }

  function handleInput ({name, value}) {
    commit('SET_SM_FORM_FIELD', {name, value})
  }

  function handleSubmit () {
    if (isCSVFile.value && invalidEmail.value) {
      return
    }
    dispatch(isCSVFile.value ? 'send_sm_data_csv' : 'send_sm_data', {formData, clbk: clearFile})
  }

  function clearFile () {
    baseFileRef.value.handleClear()
  }

  function handleSaveFilters () {
    dispatch('save_sm_filters')
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .sm-enricher-view {
    @media only screen and (min-width: 0) {
      max-width: 1176px;
      margin: 0 auto 100px;

      h1 + .base-input {
        input {
          width: 424px;
        }

        label {
          font-weight: 700;
        }
      }

      h2 {
        margin-right: 12px;
        color: $tech-gray;
        font-weight: 700;
      }

      .search-section {
        .base-file-input {
          width: 461px;
          height: 48px;
        }
      }

      .result-section {
        h2 {
          font-size: 1.8rem;
          font-weight: 700;
          color: $tech-gray;
        }
        ul {
          margin-top: 20px;
        }
        p {
          font-weight: 400;
          margin: 0 0 6px;
        }
      }

      .filters-section {
        height: 24px;
        transition: height 0.2s ease-in-out;
        overflow: hidden;
        .header {
          h2 + img {
            transform-origin: center;
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;
          }
        }

        .base-input {
          margin-top: 43px;
          display: block;
          input {
            width: 70px;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
            color: $text-gray;
          }
          span {
            margin-left: 5px;
            color: $tech-gray;
          }
          &:nth-child(4) {
            input {
              width: 50px;
            }
          }

          &:nth-child(5) {
            input {
              width: 70px;
            }
          }
          &:nth-child(4),
          &:nth-child(5) {
            input {
              width: 50px;
              padding: 15px 0;
              text-align: center;
            }
          }
        }

        .base-checkbox {
          margin-top: 43px;
        }

        .purple-btn {
          margin-top: 19px;
          padding: 15px 60px;
        }

        &.active {
          height: 620px;
          h2 + img {
            transform: rotate(0deg);
            transform-origin: center;
          }
        }
      }
    }
  }
</style>
