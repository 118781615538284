export default {
  youtube: {
    scrape : '/api/youtube/scrape',
    filters : '/api/youtube/filters',
    results: '/api/youtube/results',
  },
  youtube_light: {
    scrape : '/api/youtube/scrape/light',
    csv : '/api/youtube/scrape/csv/light',
    filters : '/api/youtube/filters',
  },
  sm_enricher: {
    scores : '/api/sm/scores',
    filters : '/api/sm/filters',
  },
  intent_tracker: {
    linkedin : '/api/linkedin',
  },
  signal: {
    client: '/api/signals/query/client',
    target: '/api/signals/query/target',
    relevantTarget: '/api/signals/query/target-emails',
    filters: '/api/signals/filters',
    epOne: '/api/signals/query/target-no-emails-ep-1',
    epTwo: '/api/signals/query/target-no-emails-ep-2',
    epThree: '/api/signals/query/target-no-emails-ep-3',
    epFour: '/api/signals/query/target-no-emails-ep-4',
  },
  competitors: '/api/signals/query/competitors',
  hosts: '/api/hg-enricher/enrich',
  use_cases: {
    scrape: '/api/use-cases/scrape',
    filters: '/api/use-cases/filters'
  },
  co_workers: {
    scrape:  '/api/co-workers/scrape',
    filters: '/api/co-workers/filters'
  },
  icp: {
    settings: '/api/icp/settings'
  }
};
