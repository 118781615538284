<template>
  <div class="youtube-light">
    <h1 class="bold">
      Relevancy Check <br>
      <span>
        Find out if a company has or not published Riverside-relevant videos on Youtube recently
      </span>
    </h1>

    <p class="form-title">
      Enter a domain or upload a CSV
    </p>

    <div class="search-section flex align-center">
      <BaseInput name="domain"
                 placeholder="https://example.com"
                 :disabled="isCSVFile"
                 :value="form.domain"
                 @enter="handleInput"
                 @input="handleInput" />
      <span class="inline-block">or</span>
      <BaseFileInput name="csvfile"
                     ref="baseFileRef"
                     label="Upload CSV (with 'domain' column)"
                     @upload="handleFile"
                     @clear="handleClearFile" />
      <BaseInput name="emailToSendCSV"
                 label="Enter the e-mail address you want to receive the results at"
                 placeholder="email address to send csv file"
                 :value="emailToSendCSV"
                 :required="isCSVFile && invalidEmail"
                 @input="handleEmail" />
    </div>

    <div class="search-section m-t-0">
      <button class="purple-btn"
              :disabled="submitDisabled"
              cy="submit"
              @click="handleSubmit">
        Submit
      </button>
    </div>

    <div>
      <div v-show="reqStatus === 'sending'" class="m-b-16">
        <BaseLoader />
        <p v-if="sseEventMsg.msg" class="text-center m0">{{ sseEventMsg.msg }}</p>
        <p v-else class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>

      <p v-show="reqStatus === 'failed'" class="text-center m-t-0 m-b-16">
        There was a server error, please try again.
        <br />
        {{ errorMsg }}
      </p>
    </div>

    <div v-if="result.length"
         cy="results"
         class="result-section m-b-24">
      <h2>Results for {{ searchName }}</h2>
      <ul>
        <li class="m-b-8"
            v-for="({name, value = '', href, img = ''}, index) in result"
            :key="index">
          <h4 class="m-b-4">{{ name }}</h4>
          <div v-if="href">
            <a :href="href" target="_blank">{{ value }}</a>
          </div>
          <figure v-else-if="img" class="m0">
            <img :src="img" alt="" />
          </figure>
          <div v-else>
            <p v-if="typeof value === 'string'"
               v-html="value.replace(/\n/g, '<br>')" />
            <p v-else-if="Array.isArray(value)">
              <span class="block"
                    v-for="(item, index) in value"
                    :key="index">{{ item }}</span>
            </p>
            <p v-else v-html="value" />
          </div>
        </li>
      </ul>
    </div>

    <div v-if="csvResult" cy="csv-results">
      <h2>Results for CSV</h2>
      <p>The csv file was sent to email.</p>
    </div>

    <section class="filters-section"
             cy="filters"
             :class="{'active-filters': isFilters}">
      <div class="header inline-flex align-center pointer"
           @click="toggleFilters">
        <h2>Filters</h2>
        <img src="../assets/arrow.svg" alt="filter arrow" />
      </div>

      <BaseInput name="keywords"
                 placeholder="keywords included separated by a comma"
                 label="Keywords that if included in the title of videos, we will scrap"
                 :value="filters.keywords"
                 @input="handleInputFilter" />

      <BaseInput name="negativeKeywords"
                 placeholder="keywords not included separated by a comma"
                 label="Keywords that if included in the title of videos, we will not scrap"
                 :value="filters.negativeKeywords"
                 @input="handleInputFilter" />

      <BaseInput name="monthsInterval"
                 placeholder="0"
                 label="Date threshold (in months). Videos before then will not be considered"
                 :value="filters.monthsInterval"
                 @input="handleInputFilter">
        <span>months</span>
      </BaseInput>

      <BaseInput name="viewCountMin"
                 placeholder="0"
                 label="View count threshold (in thousands). Videos with less than this will not be considered"
                 :value="filters.viewCountMin"
                 @input="handleInputFilter">
        <span>,000</span>
      </BaseInput>

      <div class="actions flex">
        <button class="purple-btn"
                cy="save-filters"
                @click="handleSaveFilters">Save settings</button>
      </div>
    </section>
  </div>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";
  import {useValidations} from "@/composables/validations";

  const { state, dispatch, commit } = useStore()
  dispatch('get_youtube_light_filters')
  const isCSVFile = ref(false)
  const isFilters = ref(false)
  const baseFileRef = ref(null)
  let formData = new FormData()
  const sseEventMsg = computed(() => state.sseEventMsg)
  const reqStatus = computed(() => state.reqStatus)
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const csvResult = computed(() => state.youtubeLight.csvResult)
  const errorMsg = computed(() => state.youtubeLight.errorMsg)
  const result = computed(() => state.youtubeLight.result)
  const form = computed(() => state.youtubeLight.form)
  const filters = computed(() => state.youtubeLight.filters)
  const submitDisabled = computed(() => reqStatus.value === 'sending' || (!form.value.domain && !isCSVFile.value))
  const { invalidEmail } = useValidations()

  function handleEmail ({name, value}) {
    commit('SET_EMAIL', value)
  }

  function toggleFilters () {
    isFilters.value = !isFilters.value
  }

  function handleSaveFilters () {
    dispatch('save_youtube_light_filters')
  }

  function handleInput ({name, value}) {
    commit('SET_YTL_FORM_FIELD', {name, value})
  }

  function handleInputFilter ({name, value}) {
    commit('SET_YTL_FILTER', {name, value})
  }

  function handleClearFile () {
    isCSVFile.value = false
  }

  function clearFile () {
    baseFileRef.value.handleClear()
  }

  function handleFile ({name, file}) {
    isCSVFile.value = true
    formData = new FormData()
    formData.append(name, file)
    commit('SET_YTL_FORM_FIELD', {name: 'domain', value: ''})
  }

  function handleSubmit () {
    if (isCSVFile.value && invalidEmail.value) {
      return
    }
    dispatch(isCSVFile.value ? 'send_youtube_light_data_csv' : 'send_youtube_light_data', {formData, clbk: clearFile})
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .youtube-light {
    @media only screen and (min-width: 0) {
      max-width: 1176px;
      margin: 0 auto 200px;

      h2 {
        color: $tech-gray;
        font-weight: 700;
      }

      .result-section {
        h2 {
          font-size: 1.8rem;
          font-weight: 700;
          color: $tech-gray;
        }
        ul {
          margin-top: 20px;
        }
        p {
          font-weight: 400;
          margin: 0 0 6px;
        }
        img {
          width: 320px;
        }
      }

      .filters-section {
        height: 24px;
        transition: height 0.2s ease-in-out;
        overflow: hidden;
        .header {
          h2 + img {
            transform-origin: center;
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;
          }
        }

        .base-input {
          margin-top: 43px;
          display: block;
          input {
            width: 510px;
          }
          textarea {
            height: 400px;
          }
          span {
            margin-left: 5px;
            color: $tech-gray;
          }
          &.monthsInterval,
          &.viewCountMin {
            input {
              width: 50px;
              text-align: center;
              padding: 15px 0;
            }
          }
        }

        .actions {
          margin-top: 19px;
          .purple-btn {
            padding: 15px 60px;
          }
        }

        &.active-filters {
          height: 1620px;
        }
      }
    }
  }
</style>