<template>
  <main class="icp-view">
    <h1>
      ICP analysis
    </h1>

    <p>
      Create ICP categories that will be related to LinkedIn profiles scrapped by the extension
    </p>

    <div>
      <ul cy="personas-list">
        <li class="flex flex-between align-center"
            v-for="(item, index) in data"
            :key="index">
        <span>
          {{ item.role }}
        </span>
          <button @click="handleDelete(item.id)">
            Remove
          </button>
        </li>
      </ul>

      <div class="add-persona">
        <input type="text"
               class="w100"
               cy="add-persona"
               v-model="role"
               placeholder="ICP name" />
        <br>
        <button cy="submit-persona"
                @click="handleSubmit">
          Add ICP
        </button>
      </div>
    </div>
  </main>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed, reactive, ref} from "vue";

  const { state, dispatch, commit } = useStore()
  dispatch('get_personas')
  const role = ref('')
  const data = computed(() => state.icp.data)

  function handleSubmit () {
    dispatch('save_persona', role.value)
    role.value = ''
  }

  function handleDelete (id) {
    dispatch('delete_persona', id)
  }
</script>

<style lang="scss">
  .icp-view {
    @media only screen and (min-width: 0) {
      h1 {
        font-size: 3rem;
        font-weight: 700;
        color: #5D5D5D;
        margin-bottom: 0;
      }
      h1 + p {
        margin-top: 8px;
        font-size: 1.8rem;
        font-weight: 400;
        color: #ABABAB;
      }

      > div {
        width: 400px;
      }

      ul {
        margin-top: 43px;
        li {
          margin-bottom: 15px;
          span {
            font-size: 1.8rem;
            color: #ABABAB;
          }
          button {
            padding: 6px 16px;
            font-weight: 700;
            font-size: 1.4rem;
            border-radius: 4px;
            background-color: #F8D2D2;
            color: #5D5D5D;
          }
        }
      }

      .add-persona {
        margin-top: 24px;
        input {
          border-radius: 4px;
          padding: 6px 0 4px 8px;
          border: 1px solid lightgray;
        }
        button {
          width: 224px;
          height: 48px;
          margin-top: 20px;
          border-radius: 4px;
          background-color: rgba(113, 255, 144, 0.64);
          color: #5D5D5D;
          font-weight: 700;
          font-size: 1.4rem;
        }
      }
    }
  }
</style>