import axios from "axios";
import api from "@/store/api";

export const state = {
  result: {},
  csvResult: '',
  reqStatus: 'idle',
  form: {
    email: '',
    type: 'host'
  },
  searchName: '',
  errorMsg: '',
}

export const mutations = {
  SET_HG_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_HG_FORM_FIELD (state, {name, value}) {
    state.form[name] = value
  },
}

export const actions = {
  async send_hosts_data ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_HG_STATE', {key: 'result', value: []})
      commit('SET_HG_STATE', {key: 'csvResult', value: ''})
      const {data} = await axios.post(`${api.hosts}`, {...state.form})
      commit('SET_HG_STATE', {key: 'searchName', value: state.form.email.toString()})
      commit('SET_HG_STATE', {key: 'result', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      commit('SET_HG_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  },
  async send_hosts_data_csv ({commit, rootState}, {formData, clbk = () => {}}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_HG_STATE', {key: 'result', value: []})
      commit('SET_HG_STATE', {key: 'csvResult', value: ''})
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      const {data} = await axios.post(`${api.hosts}/csv`, formData)
      commit('SET_HG_STATE', {key: 'result', value: []})
      commit('SET_HG_STATE', {key: 'csvResult', value: data})
      commit('SET_HG_STATE', {key: 'searchName', value: ''})
      commit('SET_REQ_STATUS', 'success')
      clbk();
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      console.log(err)
      commit('SET_HG_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  },
}