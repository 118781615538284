<template>
  <div class="apollo-endpoints">
    <h1 class="bold">
      Apollo endpoints <br>
      <span>Edit titles for Apollo endpoints used on Clay (no emails will be returned)</span>
    </h1>

    <div class="flex columns">
      <label>Endpoint 1: Enterprise, non-media</label>
      <span>https://riverside.testenv.ro/api/signals/query/target-no-emails-ep-1</span>
    </div>
    <BaseInput name="titlesNoEmails1"
               placeholder="product, production"
               :value="titlesNoEmails1"
               @input="handleInput"
    />

    <div class="flex columns">
      <label>Endpoint 2: Enterprise, media</label>
      <span>https://riverside.testenv.ro/api/signals/query/target-no-emails-ep-2</span>
    </div>
    <BaseInput name="titlesNoEmails2"
               placeholder="product, production"
               :value="titlesNoEmails2"
               @input="handleInput"
    />

    <div class="flex columns">
      <label>Endpoint 3: Agency, non-media</label>
      <span>https://riverside.testenv.ro/api/signals/query/target-no-emails-ep-3</span>
    </div>
    <BaseInput name="titlesNoEmails3"
               placeholder="product, production"
               :value="titlesNoEmails3"
               @input="handleInput"
    />

    <div class="flex columns">
      <label>Endpoint 4: Agency, media</label>
      <span>https://riverside.testenv.ro/api/signals/query/target-no-emails-ep-4</span>
    </div>
    <BaseInput name="titlesNoEmails4"
               placeholder="product, production"
               :value="titlesNoEmails4"
               @input="handleInput"
    />

    <button class="purple-btn"
            cy="save-endpoins"
            @click="handleSaveEndpoints">Save</button>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const { state, dispatch, commit } = useStore()
  dispatch('get_apollo_filters')
  const titlesNoEmails1 = computed(() => state.apolloEndpoints.titlesNoEmails1);
  const titlesNoEmails2 = computed(() => state.apolloEndpoints.titlesNoEmails2);
  const titlesNoEmails3 = computed(() => state.apolloEndpoints.titlesNoEmails3);
  const titlesNoEmails4 = computed(() => state.apolloEndpoints.titlesNoEmails4);

  function handleInput ({name, value}) {
    commit('SET_APOLLO_ENDPOINTS_STATE', {name, value})
  }

  function handleSaveEndpoints () {
    dispatch('save_apollo_endpoints')
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .apollo-endpoints {
    @media only screen and (min-width: 0) {
      max-width: 1076px;
      margin: 0 auto 100px;

      .base-input {
        input {
          width: 424px;
          margin-bottom: 20px;
        }
      }

      > div {
        label, span {
          margin-bottom: 5px;
          font-weight: 400;
          color: $tech-gray;
        }

        label {
          margin-top: 5px;
          font-weight: 700;
        }

        span {
          margin-bottom: 10px;
        }
      }

      button {
        width: 200px;
        margin-top: 20px;
      }
    }
  }
</style>