import { computed } from 'vue';
import {validateEmail} from "@/store/utils";
import {useStore} from "vuex";

export function useValidations () {
  const { state } = useStore()
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const invalidEmail = computed(() => !validateEmail(emailToSendCSV.value) ?? !emailToSendCSV.value);

  return {
    invalidEmail
  }
}