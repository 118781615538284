<template>
  <div class="flex">
    <SideMenu />
    <div class="main-content">
      <router-view/>
    </div>
  </div>
</template>

<script setup>
  import SideMenu from "@/components/SideMenu.vue";

  import {useStore} from "vuex";

  const { state, dispatch, commit } = useStore();

  let eventSource;
  initializeEventSource();

  function initializeEventSource () {
    eventSource = new EventSource('/events/youtube');

    eventSource.onopen = (event) => {
      console.log('SSE open');
    };

    eventSource.onmessage = (event) => {
      commit('SSE_EVENT', event.data);
    };

    eventSource.onerror = (error) => {
      console.error('Error with SSE:', error);
    };
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .main-content {
    padding-left: 43px;
    padding-right: 43px;
    h1 {
      margin: 29px 0 43px;
      line-height: 1;
      span {
        font-size: 1.8rem;
        color: $text-gray;
      }
    }
  }
</style>