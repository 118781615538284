import axios from "axios"
import api from "../api"

export const state = {
  client: {domain: ''},
  target: {email: ''},
  relevantTarget: {domain: ''},
  results: {},
  csvResult: '',
  reqStatus: 'idle',
  filters: {
    industriesToExclude: [],
    keywordsTitleStrict: [],
    keywordsTitleNonStrict: [],
    additionalKeywords: [],
    applyForStrict: '',
    applyForNonStrict: '',
    negativeKeywordsTitle: []
  },
}

export const mutations = {
  SET_VALID_TARGETS_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_CLIENT_DOMAIN (state, {name, value}) {
    state[name].domain = value
  },
  UPDATE_FILTERS (state, {name, value}) {
    state.filters[name] = value
  },
  SET_TARGET_EMAIL (state, {name, value}) {
    state[name].email = value
  },
  SET_RELEVANT_TARGET_DOMAIN (state, {name, value}) {
    state[name].domain = value
  },
  SET_SIGNAL_FILTER (state, {name, value}) {
    switch (name) {
      case 'keywordsTitleStrict':
      case 'keywordsTitleNonStrict':
      case 'additionalKeywords':
      case 'negativeKeywordsTitle':
        state.filters[name] = value.split(',')
        break
      default:
        state.filters[name] = value
    }
  },
  TOGGLE_INDUSTRY_EXCLUDE(state, index) {
    state.filters.industriesToExclude[index].checked = !state.filters.industriesToExclude[index].checked
  },
}

export const actions = {
  async get_valid_targets_filters ({commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const {data} = await axios.get(api.signal.filters)
      commit('SET_VALID_TARGETS_STATE', {key: 'filters', value: data})
      commit('SET_REQ_STATUS', 'idles')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async save_valid_targets_filters ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      await axios.put(api.signal.filters, state.filters)
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async get_valid_targets_industries_filters ({commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const {data} = await axios.get(api.signal.filters)
      commit('UPDATE_FILTERS', {name: 'industriesToExclude', value: data.industriesToExclude})
      commit('SET_REQ_STATUS', 'idles')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async query_client ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      const { data } = await axios.post(api.signal.client, {domain: state.client.domain})
      commit('SET_VALID_TARGETS_STATE', {key: 'results', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async query_client_csv ({commit, rootState}, formData) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      const { data } = await axios.post(`${api.signal.client}/csv`, formData)
      commit('SET_VALID_TARGETS_STATE', {key: 'csvResult', value: data})
      commit('SET_REQ_STATUS', 'success')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async query_target ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      const { data } = await axios.post(api.signal.target, {email: state.target.email})
      commit('SET_VALID_TARGETS_STATE', {key: 'results', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async query_target_csv ({commit, rootState}, formData) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      const { data } = await axios.post(`${api.signal.target}/csv`, formData)
      commit('SET_VALID_TARGETS_STATE', {key: 'csvResult', value: data})
      commit('SET_REQ_STATUS', 'success')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async query_relevant_target ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      const { data } = await axios.post(api.signal.relevantTarget, {domain: state.relevantTarget.domain})
      commit('SET_VALID_TARGETS_STATE', {key: 'results', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async query_relevant_target_csv ({commit, rootState}, formData) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      const { data } = await axios.post(`${api.signal.relevantTarget}/csv`, formData)
      commit('SET_VALID_TARGETS_STATE', {key: 'csvResult', value: data})
      commit('SET_REQ_STATUS', 'success')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
}