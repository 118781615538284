import axios from "axios"
import api from "../api"

export const state = {
  form: {
    keyword: '',
    is_linkedin: false,
  },
  filters: {
    timeframe: '',
    days: '',
    pages: '',
    notScrapedPosts: false,
    notRelevantTargets: false,
    notRiversideClient: false
  },
  errorMsg: '',
  result: {}
}

export const mutations = {
  SET_IT_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_IT_FORM_FIELD (state, {name, value}) {
    state.form[name] = value
  },
  SET_IT_FILTER (state, {name, value}) {
    state.filters[name] = value
  },
}

export const actions = {
  async get_intent_tracker_filters ({commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const {data} = await axios.get(`${api.intent_tracker.linkedin}/filters`)
      commit('SET_IT_STATE', {key: 'filters', value: data})
      commit('SET_REQ_STATUS', 'idles')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async save_intent_tracker_filters ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      await axios.put(`${api.intent_tracker.linkedin}/filters`, state.filters)
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async submit_linkedin ({state, commit, rootState}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      const { data } = await axios.post(`${api.intent_tracker.linkedin}/scrape`, {
        ...state.form,
        ...state.filters,
        emailToSendCSV: rootState.emailToSendCSV
      })
      commit('SET_IT_STATE', {key: 'result', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      commit('SET_IT_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  }
}