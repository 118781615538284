import axios from "axios"
import api from "../api"

export const state = {
  data: []
}

export const mutations = {
  SET_ICP_STATE (state, {key, value}) {
    state[key] = value
  },
  ADD_ROLE (state, persona) {
    state.data.push(persona)
  },
  DELETE_ROLE (state, id) {
    const index = state.data.findIndex(item => item.id === id)
    state.data.splice(index, 1)
  }
}

export const actions = {
  async get_personas ({commit}) {
    try {
      const { data } = await axios.get(`${api.icp.settings}/personas`)
      commit('SET_ICP_STATE', {key: 'data', value: data})
    } catch (err) {
      console.log(err)
    }
  },
  async save_persona ({commit}, role) {
    try {
      const { data } = await axios.post(`${api.icp.settings}/personas`, {role})
      commit('ADD_ROLE', data)
    } catch (err) {
      console.log(err)
    }
  },
  async delete_persona ({commit}, id) {
    try {
      await axios.delete(`${api.icp.settings}/personas/${id}`)
      commit('DELETE_ROLE', id)
    } catch (err) {
      console.log(err)
    }
  }
}