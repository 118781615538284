import axios from "axios";
import api from "@/store/api";

export const state = {
  result: [],
  csvResult: '',
  reqStatus: 'idles',
  form: {
    domain: "",
  },
  filters: {
    keywords: [],
    negativeKeywords: [],
    viewCountMin: 1000,
    durationMaxSeconds: 10000,
    monthsInterval: 1
  },
  searchName: '',
  errorMsg: ''
}

export const mutations = {
  SET_YT_STATE(state, {key, value}) {
    state[key] = value
  },
  SET_FORM_FIELD (state, {name, value}) {
    state.form[name] = value
  },
  SET_YOUTUBE_FILTER (state, {name, value}) {
    switch (name) {
      case 'keywords':
      case 'negativeKeywords':
      case 'negativeKeywordsTargetTitle':
        state.filters[name] = value.split(',')
        break
      default:
        state.filters[name] = value
    }
  },
}

export const actions = {
  async get_youtube_filters ({commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const {data} = await axios.get(api.youtube.filters)
      commit('SET_YT_STATE', {key: 'filters', value: data})
      commit('SET_REQ_STATUS', 'idles')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async save_youtube_filters ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      await axios.put(api.youtube.filters, state.filters)
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async send_youtube_data ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_YT_STATE', {key: 'result', value: []})
      commit('SET_YT_STATE', {key: 'csvResult', value: ''})
      const {data} = await axios.post(api.youtube.scrape, {...state.form, ...state.filters})
      commit('SET_YT_STATE', {key: 'searchName', value: state.form.domain})
      commit('SET_YT_STATE', {key: 'result', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      commit('SET_YT_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  },
  async send_youtube_data_csv ({commit, rootState}, {
    formData, clbk = () => {
    }
  }) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_YT_STATE', {key: 'result', value: []})
      commit('SET_YT_STATE', {key: 'csvResult', value: ''})
      Object.keys(state.filters)
        .forEach(key => {
          formData.append(key, state.filters[key])
        })
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      const {data} = await axios.post(`${api.youtube.scrape}/csv`, formData)
      commit('SET_YT_STATE', {key: 'result', value: []})
      commit('SET_YT_STATE', {key: 'csvResult', value: data})
      commit('SET_REQ_STATUS', 'success')
      clbk();
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      commit('SET_YT_STATE', {key: 'errorMsg', value: err.response?.data})
      clbk();
    }
  },
}