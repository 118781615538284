<template>
  <main class="magic-clips-view h100">
    <nav class="flex">
      <img src="../assets/logo.svg" alt="" />
      <button>Get Started</button>
    </nav>
    <div>
      <div class=" top-div flex">
        <div class="left-div">
          <h1>Your clips are ready</h1>
          <div class="flex">
            <button>Explore other features</button>
            <a href="#">Download your clips</a>
          </div>
            <p>Trusted by individuals & businesses</p>
          <div class="flex">
            <figure v-for="competitor in videoData.competitorsQueried" :key="competitor.competitorDomain">
              <img :src="competitor.competitorLogo" :alt="competitor.competitorCompany" />
            </figure>
          </div>
        </div>

        <div class="right-div" v-if="videoData">
          <div class="flex">
            <a :href="videoData.videoUrl">
              <img :src="videoData.videoThumbnail" alt="Video Thumbnail">
            </a>
            <a :href="videoData.videoUrl">
              <img :src="videoData.secondThumbnail" alt="Second Thumbnail">
            </a>
          </div>
          <div class="flex">
            <span>{{videoResolution}}</span>
            <span>0:00:00 - {{videoDuration}}</span>
          </div>
          <figure class="flex">
            <img src="../assets/youtube-logo.png" />
            <a :href="videoData.videoUrl">{{ videoData.videoTitle }}</a>
          </figure>
        </div>
      </div>

      <div class="flex bottom-div">
        <h2>What else can you do with Riverside?</h2>
        <nav class="flex flex-between">
          <a :href="href" target="_blank"
             v-for="({title, href}, index) in actions"
             :key="index">
            {{ title }}
          </a>
        </nav>
      </div>
    </div>
  </main>
</template>

<script setup>
  import {useStore} from "vuex";
  import {useRoute} from "vue-router";
  import {computed} from "vue";

  const {state, dispatch} = useStore();
  const route = useRoute();

  dispatch("get_video_info", route.params.id);

  const videoData = computed(() => state.magicClips.data);

  const resolutionMap = {
    '426': '480p',
    '640': '480p',
    '1280': '720p',
    '1920': '1080p',
    '3840': '4k'
  };

  const videoResolution = computed(() => {
    const width = videoData.value?.videoResolution?.width;
    return resolutionMap[width] || 'Unknown Resolution';
  });

  function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hours}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  }

  const videoDuration = computed(() => {
    const duration = videoData.value?.videoDuration;
    return duration ? formatDuration(duration) : '0:00:00';
  });

  const actions = [
    { id: 'podcats', title: 'Podcasts', href: '#' },
    { id: 'interviews', title: 'Video interviews' },
    { id: 'social', title: 'AI social media clips' },
    { id: 'transcriptions', title: 'Transcriptions' },
    { id: 'webinars', title: 'Webinars' },
    { id: 'panel', title: 'Panel discussions' },
  ];
</script>

<style lang="scss">
  @import "../styles/vars";

  .magic-clips-view {
    //height: 100%;
    background-color: $darker-grey;

    nav {
      justify-content: space-between;
      padding: 12px 12px 12px 20px;
      align-items: center;

      img {
        width: 135.1px;
        height: 23.5px;
        filter: invert(1);
      }

      button {
        padding: 15.5px 14px;
        text-align: center;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        color: white;
        font-size: 13px;
        font-weight: 700;
      }
    }

    > div {
      max-width: 1148px;
      margin: 0 auto;
      padding: 100px 0 50px 0;
    }

    .top-div {
      justify-content: space-between;
      align-items: center;
    }

    .left-div {
      max-width: 575px;
      h1 {
        font-size: 61px;
        font-weight: 800;
        color: white;
      }

     div {
        align-items: center;
        margin: 30px 0;
        font-size: 14px;

        button{
          margin-right: 35px;
          padding: 15px 30px;
          background-color: $purple-button;
          color: white;
          border-radius: 4px;
          font-weight: 700;
        }

        a {
          text-decoration: underline;
          color: white;
          font-weight: 700;
        }
      }

      p {
        color: $tech-gray;
        font-weight: 400;
      }

      p + div {
        figure {
          margin: 0;
          img{
            width: 50px;
            margin-right: 20px;
          }
        }
      }
    }

    .right-div {
      color: $tech-gray;

      div:nth-child(1){
        img {
          width: 200px;
          height: 200px;
          margin-right: 10px;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      div:nth-child(2){
        span {
          height: 33px;
          padding: 6px 28px;
          margin: 10px 20px 0 0;
          color: white;
          font-size: 15px;
          font-weight: 700;
          background-color: #383838;
          border: 2px solid #383838;
          border-radius: 4px;
          text-align: center;
        }
      }

      figure {
        height: 30px;
        margin: 18px 0;
        align-items: center;

        img {
          height: 21px;
          margin-right: 10px;
        }

        a {
          color: $tech-gray;
          text-decoration: none;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .bottom-div {
      margin-top: 60px;
      padding: 33px 30px;
      flex-direction: column;
      align-items: center;
      background-color: $dark-grey;
      border-radius: 4px;

      h2 {
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 700;
        color: white;
      }

      nav {
        a {
          height: 54px;
          margin-right: 10px;
          padding: 15px 24px;
          color: white;
          background-color: $darker-grey;
          border: 1px solid $purple-outline;
          border-radius: 94px;
          font-size: 16px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
</style>