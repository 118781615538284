import {ref} from "vue";

export function useFilters () {
  const isFilters = ref(false)

  function toggleFilters () {
    isFilters.value = !isFilters.value
  }

  return {
    isFilters,
    toggleFilters
  }
}