<template>
  <div class="hosts-view">
    <h1 class="bold">
      Hosts/ Guests matchmaking <br>
      <span>Generate personalized emails to hosts or guests to ask them to join the matchmaking pilot</span>
    </h1>

    <BaseInput name="emailToSendCSV"
               label="Enter the e-mail address you want to receive the results at"
               placeholder="email address to send csv file"
               :value="emailToSendCSV"
               :required="isCSVFile && invalidEmail"
               @input="handleEmail" />

    <p class="form-title">
      Enter a professional email or upload a CSV
    </p>

    <div class="search-section flex align-center">
      <BaseInput name="email"
                 placeholder="bill@microsoft.com"
                 :disabled="isCSVFile"
                 :value="form.email"
                 @enter="handleSubmit"
                 @input="handleInput" />
      <span>or</span>
      <BaseFileInput name="csvfile"
                     ref="baseFileRef"
                     label="Upload CSV (with ‘email’ column)"
                     @upload="handleFile"
                     @clear="handleClearFile" />

      <div class="flex columns">
        <span>Generate email content for (select one):</span>
        <div class="flex">
          <BaseCheckbox name="host"
                        :value="form.type === 'host'"
                        @click="handleType"
                        label="Hosts" />

          <BaseCheckbox name="guest"
                        :value="form.type === 'guest'"
                        @click="handleType"
                        label="Guests" />
        </div>
        <button class="purple-btn"
                :disabled="submitDisabled"
                cy="submit"
                @click="handleSubmit">Submit</button>
      </div>
    </div>

    <div>
      <div v-show="reqStatus === 'sending'" class="m-b-16">
        <BaseLoader />
        <p class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>

      <p v-show="reqStatus === 'failed'" class="text-center m-t-0 m-b-16">
        There was a server error, please try again. <br>
        {{ errorMsg }}
      </p>
    </div>

    <div v-if="result.email"
         cy="results"
         class="result-section m-b-24 m-t-24">
      <h2>Results for {{ searchName }}</h2>
      <ul>
        <li v-for="(item, key) in result"
            :key="key">
          <p>{{ key }}: {{ item }}</p>
        </li>
      </ul>
    </div>

    <div v-if="csvResult"
         cy="csv-results"
         class="result-csv">
      <h2>Results for CSV</h2>
      <p>The csv file was sent to email.</p>
    </div>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, ref} from "vue";
  import {useStore} from "vuex";
  import {useValidations} from "@/composables/validations";

  const { state, dispatch, commit } = useStore()

  const reqStatus = computed(() => state.reqStatus)
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const errorMsg = computed(() => state.hostsGuests.errorMsg)
  const form = computed(() => state.hostsGuests.form)
  const result = computed(() => state.hostsGuests.result)
  const csvResult = computed(() => state.hostsGuests.csvResult)
  const searchName = computed(() => state.hostsGuests.searchName)
  const isCSVFile = ref(false)
  const submitDisabled = computed(() => reqStatus.value === 'sending' || (!form.value.email && !isCSVFile.value))
  let formData = new FormData()
  const baseFileRef = ref(null)
  const { invalidEmail } = useValidations()

  function handleEmail ({name, value}) {
    commit('SET_EMAIL', value)
  }

  function handleFile ({name, file}) {
    isCSVFile.value = true
    formData = new FormData()
    formData.append(name, file)
    formData.append('type', form.value.type)
    commit('SET_HG_FORM_FIELD', {name: 'name', value: ''})
  }

  function handleType ({name}) {
    commit('SET_HG_FORM_FIELD', {name: 'type', value: name})
    formData.append('type', name)
  }

  function handleClearFile () {
    isCSVFile.value = false
  }

  function handleInput ({name, value}) {
    commit('SET_HG_FORM_FIELD', {name, value})
  }

  function handleSubmit () {
    if (isCSVFile.value && invalidEmail.value) {
      return
    }
    const action = isCSVFile.value ? 'send_hosts_data_csv'  : 'send_hosts_data'
    dispatch(action, {formData, clbk: clearFile})
  }

  function clearFile () {
    baseFileRef.value.handleClear()
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .hosts-view {
    @media only screen and (min-width: 0) {
      max-width: 1176px;
      margin: 0 auto 100px;

      h1 + .base-input {
        input {
          width: 424px;
          margin-bottom: 20px;
        }
        label {
          font-weight: 700;
        }
      }

        .search-section {
        .base-checkbox span {
          display: flex;
        }

        .base-file-input + div {
          span {
            margin-left: 0;
          }
        }
      }

      span + div {
        .base-checkbox {
          margin: -10px 20px 30px 0;

          span {
            margin: 0;
          }
        }
      }

      .result-section {
        h2 {
          font-size: 1.8rem;
          font-weight: 700;
          color: $tech-gray;
        }
        ul {
          margin-top: 20px;
        }
        p {
          font-weight: 400;
          margin: 0 0 6px;
        }
      }

      .result-csv {
        margin-top: 30px;
      }
    }
  }
</style>