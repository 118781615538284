<template>
  <div class="base-checkbox relative flex align-center"
       :cy="cy || name">
    <span class="flex flex-center pointer"
          @click="handleClick">
      <span v-show="value">X</span>
    </span>
    <label :for="id || name"
           class="m-l-16">{{ label || name}}</label>
    <input type="checkbox"
           :id="id || name"
           @change="handleClick"
           :checked="value" />
  </div>
</template>

<script setup>
  const props = defineProps({
    id: String,
    cy: String,
    name: String,
    label: String,
    value: Boolean
  })

  const emits = defineEmits(['click'])

  function handleClick () {
    emits('click', {name: props.name, value: !props.value})
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-checkbox {
    > span {
      height: 30px;
      width: 30px;
      flex-shrink: 0;
      border: 0.5px solid black;
      border-radius: 4px;
      span {
        font-size: 3rem;
        color: $text-gray;
      }
    }
    input {
      visibility: hidden;
      margin: 0;
      position: absolute;
    }
  }
</style>