import axios from "axios"
import api from "../api"

export const state = {
  competitors: {domain: ''},
  results: {},
  csvResult: '',
  reqStatus: 'idle',
}

export const mutations = {
  SET_COMPETITORS_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_COMPETITORS_DOMAIN (state, {name, value}) {
    state[name].domain = value
  },
  RESET_COMPETITORS_STATE (state) {
    state.results = {};
    state.csvResult = '';
    state.reqStatus = 'idle';
    state.competitors.domain = '';
  },
}

export const actions = {
  async query_competitors ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_COMPETITORS_STATE', {key: 'results', value: {}})
      const { data } = await axios.post(api.competitors, {domain: state.competitors.domain})
      commit('SET_COMPETITORS_STATE', {key: 'results', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async query_competitors_csv ({commit, rootState}, {formData, clbk = () => {}}) {
    try {
      commit('RESET_COMPETITORS_STATE')
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_COMPETITORS_STATE', {key: 'results', value: []})
      commit('SET_COMPETITORS_STATE', {key: 'csvResult', value: ''})
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      const {data} = await axios.post(`${api.competitors}/csv`, formData)
      commit('SET_COMPETITORS_STATE', {key: 'results', value: []})
      commit('SET_COMPETITORS_STATE', {key: 'csvResult', value: data})
      commit('SET_REQ_STATUS', 'success')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      clbk();
    }
  },
}