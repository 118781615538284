import axios from "axios"
import api from "../api"

export const state = {
  titlesNoEmails1: [],
  titlesNoEmails2: [],
  titlesNoEmails3: [],
  titlesNoEmails4: [],
}

export const mutations = {
  SET_APOLLO_ENDPOINTS_STATES(state, data) {
    state.titlesNoEmails1 = data.titlesNoEmails1;
    state.titlesNoEmails2 = data.titlesNoEmails2;
    state.titlesNoEmails3 = data.titlesNoEmails3;
    state.titlesNoEmails4 = data.titlesNoEmails4;
  },
  SET_APOLLO_ENDPOINTS_STATE (state, {name, value}) {
    state[name] = value.split(',').map(item => item.trim());
  },
  RESET_APOLLO_ENDPOINTS_STATE (state) {
    state.titlesNoEmails1 = [];
    state.titlesNoEmails2 = [];
    state.titlesNoEmails3 = [];
    state.titlesNoEmails4 = [];
  },
}

export const actions = {
  async get_apollo_filters ({commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const {data} = await axios.get(api.signal.filters)
      commit('SET_APOLLO_ENDPOINTS_STATES', data)
      commit('SET_REQ_STATUS', 'idles')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async save_apollo_endpoints ({ state, commit }) {
    try {
      commit('SET_REQ_STATUS', 'fetching')

      const payload = {
        titlesNoEmails1: state.titlesNoEmails1,
        titlesNoEmails2: state.titlesNoEmails2,
        titlesNoEmails3: state.titlesNoEmails3,
        titlesNoEmails4: state.titlesNoEmails4,
      }

      await axios.put(api.signal.filters, payload)
      commit('SET_APOLLO_ENDPOINTS_STATES', payload)
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  }
}