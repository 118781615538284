<template>
  <div class="h100">
    <router-view />
  </div>
</template>

<script setup>

</script>

<style lang="scss">
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 62.5%;
  }

  #app {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }
</style>