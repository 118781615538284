import axios from "axios";
import api from "@/store/api";
import result from '../../../cypress/fixtures/use-cases.json'

export const state = {
  result: [],
  csvResult: '',
  reqStatus: 'idles',
  form: {
    domain: "",
  },
  filters: {
    keywords: [],
    keywordsWithVideo: [],
    viewCountMin: '',
    monthsKeywordInterval: '',
    monthsInterval: ''
  },
  searchName: '',
  errorMsg: ''
}

export const mutations = {
  SET_UC_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_UC_FORM_FIELD (state, {name, value}) {
    state.form[name] = value
  },
  SET_UC_FILTER (state, {name, value}) {
    switch (name) {
      case 'keywords':
      case 'keywordsWithVideo':
        state.filters[name] = value.split(',')
        break
      default:
        state.filters[name] = value
    }
  },
}

export const actions = {
  async get_cases_filters ({commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const {data} = await axios.get(api.use_cases.filters)
      commit('SET_UC_STATE', {key: 'filters', value: data})
      commit('SET_REQ_STATUS', 'idles')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async save_cases_filters ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      await axios.put(api.use_cases.filters, state.filters)
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async send_cases_data ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_UC_STATE', {key: 'result', value: []})
      commit('SET_UC_STATE', {key: 'csvResult', value: ''})
      const {data} = await axios.post(`${api.use_cases.scrape}`, {...state.form, ...state.filters})
      commit('SET_UC_STATE', {key: 'searchName', value: state.form.domain})
      commit('SET_UC_STATE', {key: 'result', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      commit('SET_UC_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  },
  async send_cases_data_csv ({commit, rootState}, {
    formData, clbk = () => {
    }
  }) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_UC_STATE', {key: 'result', value: []})
      commit('SET_UC_STATE', {key: 'csvResult', value: ''})
      Object.keys(state.filters)
        .forEach(key => {
          formData.append(key, state.filters[key])
        })
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      const {data} = await axios.post(`${api.use_cases.scrape}/csv`, formData)
      commit('SET_UC_STATE', {key: 'result', value: []})
      commit('SET_UC_STATE', {key: 'csvResult', value: data})
      commit('SET_UC_STATE', {key: 'searchName', value: ''})
      commit('SET_REQ_STATUS', 'success')
      clbk();
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      console.log(err)
      commit('SET_UC_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  },
}