<template>
  <div class="valid-targets">
    <h1 class="bold">
      Valid Targets <br>
      <span>Find out who can we reach to</span>
    </h1>

    <BaseInput name="emailToSendCSV"
               label="Enter the e-mail address you want to receive the results at"
               placeholder="email address to send csv file"
               :value="emailToSendCSV"
               :required="(isCSVFile.client || isCSVFile.target || isCSVFile.relevantTarget) && invalidEmail"
               @input="handleEmail" />

    <div class="search-section flex flex-between align-center">
      <p>
        Who is a client (Self serve or B2B? Enter email/ domain or upload a CSV and we will tell you who has a RS account (using Type for domains and Lead type/ Customer plan for individuals).
      </p>
      <BaseInput name="client"
                 placeholder="jon@brex.com or brex.com"
                 :disabled="isCSVFile.client"
                 @input="handleInput" />
      <span class="inline-block">or</span>
      <BaseFileInput name="csvfile"
                     ref="baseFileRef"
                     label="Upload CSV (with 'email' or 'domain' columns)"
                     @upload="handleClientFile"
                     @clear="handleClearFile" />

      <button class="purple-btn"
              :disabled="submitClientDisabled"
              cy="submit"
              @click="handleSubmitClient">
        Submit
      </button>
    </div>

    <div class="search-section flex flex-between align-center">
      <p>
        Is this target a good target for RS? Based on their title, industry etc.
      </p>
      <BaseInput name="target"
                 placeholder="john@brex.com"
                 :disabled="isCSVFile.target"
                 @input="handleTargetInput" />
      <span class="inline-block">or</span>
      <BaseFileInput name="targetcsvfile"
                     ref="baseFileRef"
                     label="Upload CSV (must include 'email' column)"
                     @upload="handleTargetFile"
                     @clear="handleClearFile" />

      <button class="purple-btn"
              :disabled="submitTargetDisabled"
              cy="submit"
              @click="handleSubmitTarget">
        Submit
      </button>
    </div>

    <div class="search-section flex flex-between align-center">
      <p>
        Find relevant targets working in companies based on industry/ company size.
      </p>
      <BaseInput name="relevantTarget"
                 placeholder="brex.com"
                 :disabled="isCSVFile.relevantTarget"
                 @input="handleRelevantTargetInput" />
      <span class="inline-block">or</span>
      <BaseFileInput name="relevantTargetcsvfile"
                     ref="baseFileRef"
                     label="Upload CSV (must include 'domain' column)"
                     @upload="handleRelevantTargetFile"
                     @clear="handleClearFile" />

      <button class="purple-btn"
              :disabled="submitRelevantTargetDisabled"
              cy="submit"
              @click="handleSubmitRelevantTarget">
        Submit
      </button>
    </div>

    <div class="results">
      <div v-show="reqStatus === 'sending'">
        <BaseLoader />
        <p class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>
    </div>

    <div v-if="Object.keys(results).length"
         cy="results"
         class="result-section m-b-24 m-t-24">
      <h2>Results</h2>
      <ul>
        <li v-for="(item, key) in results"
            :key="key">
          <p>{{ key }}: {{ item }}</p>
        </li>
      </ul>
    </div>

    <div v-if="csvResult"
         cy="csv-results"
         class="result-csv">
      <h2>Results for CSV</h2>
      <p>The csv file was sent to email.</p>
    </div>

    <!-- FILTERS -->
    <section class="filters-section"
             cy="filters"
             :class="{'active-filters': isFilters}">
      <div class="header inline-flex align-center pointer"
           @click="toggleFilters">
        <h2>Filters</h2>
        <img src="../assets/arrow.svg" alt="filter arrow" />
      </div>

      <BaseInput name="keywordsTitleNonStrict"
                 placeholder="roles separated by a comma"
                 label="Roles for non-strict industries (media etc..). If ‘contains’, we will approve the title.” industries."
                 :value="filters.keywordsTitleNonStrict"
                 @input="handleInputFilter" />

      <BaseInput name="keywordsTitleStrict"
                 placeholder="keywords not included separated by a comma"
                 label="Roles for strict industries. This is a white list - only titles containing one of those will be approved for companies from “Strict” industries."
                 :value="filters.keywordsTitleStrict"
                 @input="handleInputFilter" />

      <p>For companies with up to 1000 employees (agencies), ALSO pick up the following roles:</p>
      <div class="flex">
        <BaseCheckbox name="applyForStrict"
                      :value="filters.applyForStrict"
                      @click="handleInputFilter"
                      label="Apply for strict companies" />

        <BaseCheckbox name="applyForNonStrict"
                      :value="filters.applyForNonStrict"
                      @click="handleInputFilter"
                      label="Apply for non-strict companies" />
      </div>
      <BaseInput name="additionalKeywords"
                 placeholder="keywords not included separated by a comma"
                 :value="filters.additionalKeywords"
                 @input="handleInputFilter" />

      <BaseInput name="negativeKeywordsTitle"
                 placeholder="keywords not included separated by a comma"
                 label="Negative keywords. If one of these keywords is contained in any title (Strict or Non-Strict industries), it will not be approved."
                 :value="filters.negativeKeywordsTitle"
                 @input="handleInputFilter" />

      <p>Mark below the Strict industries (non-selected will be considered as non-strict industries):</p>

      <button class="select-all-industries-btn"
              @click="toggleSelectAll">
        {{ selectAllText }}
      </button>

      <button class="reset-industries-btn select-all-industries-btn"
              @click="handleResetFilterIndustries">
        Reset to initial values
      </button>

      <div class="industry-filters flex flex-wrap">
        <BaseCheckbox v-for="(industry, index) in filters.industriesToExclude"
                      :key="index"
                      :name="'industriesToExclude_' + index"
                      :value="industry.checked"
                      @click="() => handleCheckboxIndustries(index)"
                      :label="industry.industry" />
      </div>

      <div class="actions flex">
        <button class="purple-btn"
                cy="save-filters"
                @click="handleSaveFilters">Save settings</button>
      </div>
    </section>
  </div>
</template>

<script setup>
  import {computed, onMounted, ref} from "vue";
  import {useStore} from "vuex";
  import {useValidations} from "@/composables/validations";

  const { state, dispatch, commit } = useStore()
  dispatch('get_valid_targets_filters')
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const results = computed(() => state.signal.results)
  const csvResult = computed(() => state.signal.csvResult)
  const client = computed(() => state.signal.client)
  const target = computed(() => state.signal.target)
  const relevantTarget = computed(() => state.signal.relevantTarget)
  const form = computed(() => state.signal.form)
  const reqStatus = computed(() => state.signal.reqStatus)
  const isCSVFile = ref({client: false, target: false, relevantTarget: false})
  let clientFormData = new FormData();
  let targetFormData = new FormData();
  let relevantTargetFormData = new FormData();
  const isFilters = ref(false)
  const filters = computed(() => state.signal.filters)
  const submitClientDisabled = computed(() => reqStatus.value === 'sending' || (!client.value.domain && !isCSVFile.value.client))
  const submitTargetDisabled = computed(() => reqStatus.value === 'sending' || (!target.value.email && !isCSVFile.value.target))
  const submitRelevantTargetDisabled = computed(() => reqStatus.value === 'sending' || (!relevantTarget.value.domain && !isCSVFile.value.relevantTarget))
  const { invalidEmail } = useValidations()

  function handleResetFilterIndustries () {
    dispatch('get_valid_targets_industries_filters')
  }

  function handleEmail ({name, value}) {
    commit('SET_EMAIL', value)
  }

  function toggleFilters () {
    isFilters.value = !isFilters.value
  }

  function handleSaveFilters () {
    dispatch('save_valid_targets_filters')
  }

  function handleInputFilter ({name, value}) {
    commit('SET_SIGNAL_FILTER', {name, value})
  }

  function handleCheckboxIndustries(index) {
    commit('TOGGLE_INDUSTRY_EXCLUDE', index)
  }

  const allSelected = computed(() =>
      filters.value.industriesToExclude.every(industry => industry.checked)
  );

  const selectAllText = computed(() => (allSelected.value ? 'Deselect All' : 'Select All'));

  const toggleSelectAll = () => {
    const newCheckedStatus = !allSelected.value;
    filters.value.industriesToExclude.forEach(industry => {
      industry.checked = newCheckedStatus;
    });
  };

  function handleClientFile ({name, file}) {
    isCSVFile.value.client = true
    clientFormData = new FormData()
    clientFormData.append(name, file)
  }

  function handleTargetFile ({name, file}) {
    isCSVFile.value.target = true
    targetFormData = new FormData()
    targetFormData.append('csvfile', file)
  }

  function handleRelevantTargetFile ({name, file}) {
    isCSVFile.value.relevantTarget = true
    relevantTargetFormData = new FormData()
    relevantTargetFormData.append('csvfile', file)
  }

  function handleClearFile () {
    isCSVFile.value = {client: false, target: false, relevantTarget: false}
    clientFormData = new FormData();
    targetFormData = new FormData();
    relevantTargetFormData = new FormData();
  }

  function handleInput ({name, value}) {
    commit('SET_CLIENT_DOMAIN', {name, value})
  }

  function handleTargetInput ({name, value}) {
    commit('SET_TARGET_EMAIL', {name, value})
  }

  function handleRelevantTargetInput ({name, value}) {
    commit('SET_RELEVANT_TARGET_DOMAIN', {name, value})
  }

  function handleSubmitClient () {
    if (isCSVFile.value.client && invalidEmail.value) {
      return
    }
    dispatch(isCSVFile.value.client ? 'query_client_csv' : 'query_client', clientFormData)
  }

  function handleSubmitTarget () {
    if (isCSVFile.value.target && invalidEmail.value) {
      return
    }
    dispatch(isCSVFile.value.target ? 'query_target_csv' : 'query_target', targetFormData)
  }

  function handleSubmitRelevantTarget () {
    if (isCSVFile.value.relevantTarget && invalidEmail.value) {
      return
    }
    dispatch(isCSVFile.value.relevantTarget ? 'query_relevant_target_csv' : 'query_relevant_target', relevantTargetFormData)
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .valid-targets {
    @media only screen and (min-width: 0) {
      max-width: 956px;
      margin: 0 auto 100px;

      .search-section {
        p {
          color: $tech-gray;
          font-weight: 700;
        }
      }

      .purple-btn {
        width: 224px;
        margin: 0;
      }

      .base-input {
        input {
          width: 424px;
        }
        label {
          font-weight: 700;
        }
        span {
          margin-left: 5px;
          color: $tech-gray;
        }
      }

      .result-csv {
        margin-top: 30px;
      }

      .filters-section {
        transition: height 0.2s ease-in-out;
        overflow: hidden;
        margin-top: 30px;

        .industry-filters {
          .base-checkbox {
            margin-right: 24px;
          }
        }

        .base-input {
          input {
            width: 950px;
          }
          label {
            font-weight: 400;
          }
        }

        p {
          margin-top: 30px;
          color: $tech-gray;
        }

        p + div {
          .base-checkbox {
            margin: 10px 20px 0 0;
          }
        }

        &.active-filters {
          height: 100%;
        }

        .select-all-industries-btn {
          padding: 10px 20px;
          margin-right: 24px;
          border: 2px solid $purple-outline;
          border-radius: 4px;
          background-color: $purple-outline;
          color: white;
          font-size: 1.4rem;
          font-weight: 700;
        }

        .reset-industries-btn {
          background-color: gray;
          border: none;
        }
      }
    }
  }
</style>