<template>
  <div class="base-input"
       :class="`${name} ${classes} ${required ? 'required-field': ''}`">
    <label v-show="label"
           :for="id || name">
      {{ label }}
    </label>
    <textarea v-if="type === 'textarea'"
              :name="name"
              class="w100"
              :placeholder="placeholder || name"
              :disabled="disabled"
              :value="value"
              :cy="name"
              @input="handleInput" />
    <input v-else
           :type="type || 'text'"
           class="w100"
           :id="id || name"
           :value="value"
           :cy="name"
           :placeholder="placeholder || name"
           :disabled="disabled"
           @keyup.enter="handleEnterKey"
           @input="handleInput" />
    <slot />
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    label: String,
    type: String,
    id: String,
    classes: String,
    value: [String, Number, Array],
    placeholder: String,
    disabled: Boolean,
    required: Boolean,
  })

  const emits = defineEmits(['input', 'enter'])

  function handleInput ({target}) {
    emits('input', {name: props.name, value: target.value})
  }

  function handleEnterKey () {
    emits('enter', {name: props.name})
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-input {
    input,
    textarea {
      border-radius: 4px;
      border: 0.5px solid black;
    }
    input {
      padding: 15px 30px;
      font-size: 1.4rem;
      font-weight: 700;
      &::placeholder {
        color: $gray;
      }
    }
    textarea {
      padding: 8px 18px;
      line-height: 2.4rem;
    }
    &.required-field {
      input, textarea {
        border: 1px solid red;
      }
    }
  }
</style>