<template>
  <div class="use-cases-view">
    <h1 class="bold">
      Use cases <br>
      <span>Find out if a company has a use case for Riverside, across their website and youtube channel</span>
    </h1>

    <BaseInput name="emailToSendCSV"
               label="Enter the e-mail address you want to receive the results at"
               placeholder="email address to send csv file"
               :value="emailToSendCSV"
               :required="isCSVFile && invalidEmail"
               @input="handleEmail" />

    <p class="form-title">
      Enter a domain or upload a CSV
    </p>

    <div class="search-section flex align-center">
      <BaseInput name="domain"
                 placeholder="microsoft.com"
                 :disabled="isCSVFile"
                 :value="form.domain"
                 @enter="handleInput"
                 @input="handleInput" />
      <span class="inline-block">or</span>
      <BaseFileInput name="csvfile"
                     ref="baseFileRef"
                     label="Upload CSV (with 'domain' column)"
                     @upload="handleFile"
                     @clear="handleClearFile" />
      <button class="purple-btn"
              :disabled="submitDisabled"
              cy="submit"
              @click="handleSubmit">Submit</button>
    </div>

    <div>
      <div v-show="reqStatus === 'sending'" class="m-b-16">
        <BaseLoader />
        <p class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>

      <p v-show="reqStatus === 'failed'" class="text-center m-t-0 m-b-16">
        There was a server error, please try again. <br>
        {{ errorMsg }}
      </p>
    </div>

    <!-- TEXT RESULTS -->
    <div v-if="result.length"
         cy="results"
         class="result-section m-b-24">
      <h2>Results for {{ searchName }}</h2>
      <ul>
        <li class="m-b-24"
            v-for="(item, index) in result"
            :key="index">
          <div v-if="!item.isDomainRelevant">
            <details>
              <summary>Domain is not relevant for {{ item.keyword }}</summary>
              <div>
                <p v-for="(val, k) in item"
                   :key="k">
                  {{ formatKey(k) }}: {{ val }}
                </p>
                <!--                  <p v-if="isLink(key, value)">-->
                <!--                    <strong>{{ formatKey(key) }}:</strong> <a :href="value" target="_blank">{{ value }}</a>-->
                <!--                  </p>-->
                <!--                  <p v-else-if="key === 'foundOn'">-->
                <!--                    <span class="capitalize">{{ item.keyword }}&nbsp;</span>-->
                <!--                    <strong>{{ formatKey(key) }}:</strong> {{ value }}-->
                <!--                  </p>-->
                <!--                  <details v-else-if="key === 'cleanupPage'" class="m-t-8 m-b-8">-->
                <!--                    <summary>-->
                <!--                      {{ formatKey(key) }} for <span class="capitalize">{{ item.keyword }}</span>-->
                <!--                    </summary>-->
                <!--                    <p>{{ value }}</p>-->
                <!--                  </details>-->
                <!--                  <p v-else>-->
                <!--                    <strong>{{ formatKey(key) }}:</strong> {{ value }}-->
                <!--                  </p>-->
              </div>
            </details>
          </div>

          <div v-else
               v-for="(value, key) in item"
               :key="key">
            <p v-if="isLink(key, value)">
              <strong>{{ formatKey(key) }}:</strong> <a :href="value" target="_blank">{{ value }}</a>
            </p>
            <p v-else-if="key === 'foundOn'">
              <span class="capitalize">{{ item.keyword }}&nbsp;</span>
              <strong>{{ formatKey(key) }}:</strong> {{ value }}
            </p>
            <details open v-else-if="key === 'relevancyDetails'" class="m-t-8 m-b-8">
              <summary>
                {{ formatKey(key) }} for <span class="capitalize">{{ item.keyword }}</span>
              </summary>
              <p>{{ value }}</p>
            </details>
            <details v-else-if="key === 'cleanupPage'" class="m-t-8 m-b-8">
              <summary>
                {{ formatKey(key) }} for <span class="capitalize">{{ item.keyword }}</span>
              </summary>
              <p>{{ value }}</p>
            </details>
            <p v-else>
              <strong v-show="!!value">{{ formatKey(key) }}:</strong> {{ value }}
            </p>
          </div>
          <hr>
        </li>
      </ul>
    </div>

    <!-- CSV RESULTS -->
    <div v-if="csvResult"
         cy="csv-results">
      <h2>Results for CSV</h2>
      <p>The csv file was sent to email.</p>
    </div>

    <!-- FILTERS -->
    <section class="filters-section"
             cy="filters"
             :class="{'active-filters': isFilters}">
      <div class="header inline-flex align-center pointer"
           @click="toggleFilters">
        <h2>Filters</h2>
        <img src="../assets/arrow.svg" alt="filter arrow" />
      </div>

      <BaseInput name="keywords"
                 placeholder="keywords included separated by a comma"
                 label="Keywords we will look for on the company’s website and doesn’t require a media player:"
                 :value="filters.keywords"
                 @input="handleInputFilter" />

      <BaseInput name="keywordsWithVideo"
                 placeholder="keywords included separated by a comma"
                 label="Keywords we will look for on the company’s website and requires a video player next to it:"
                 :value="filters.keywordsWithVideo"
                 @input="handleInputFilter" />

      <p>Keywords we will look for on youtube videos, are all the keywords above.</p>

      <BaseInput name="monthsKeywordInterval"
                 placeholder="0"
                 label="In case we know when a webpage for a keyword was published - do not get data if the page is more than x months old"
                 :value="filters.monthsKeywordInterval"
                 @input="handleInputFilter">
        <span>months</span>
      </BaseInput>

      <BaseInput name="monthsInterval"
                 placeholder="0"
                 label="Date threshold (in months). Videos before then will not be considered"
                 :value="filters.monthsInterval"
                 @input="handleInputFilter">
        <span>months</span>
      </BaseInput>

      <BaseInput name="viewCountMin"
                 placeholder="0"
                 label="View count threshold (in thousands). Videos with less than this will not be considered"
                 :value="filters.viewCountMin"
                 @input="handleInputFilter">
        <span>,000</span>
      </BaseInput>

      <div class="actions flex">
        <button class="purple-btn"
                cy="save-filters"
                @click="handleSaveFilters">Save settings</button>
      </div>
    </section>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, ref} from "vue";
  import {useStore} from "vuex";
  import {useValidations} from "@/composables/validations";
  
  const { state, dispatch, commit } = useStore()
  dispatch('get_cases_filters')
  const reqStatus = computed(() => state.reqStatus)
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const errorMsg = computed(() => state.useCases.errorMsg)
  const form = computed(() => state.useCases.form)
  const result = computed(() => state.useCases.result)
  const csvResult = computed(() => state.useCases.csvResult)
  const searchName = computed(() => state.useCases.searchName)
  let formData = new FormData()
  const isCSVFile = ref(false)
  const baseFileRef = ref(null)
  const isFilters = ref(false)
  const filters = computed(() => state.useCases.filters)
  const submitDisabled = computed(() => reqStatus.value === 'sending' || (!form.value.domain && !isCSVFile.value))
  const { invalidEmail } = useValidations()

  function handleEmail ({name, value}) {
    commit('SET_EMAIL', value)
  }

  function toggleFilters () {
    isFilters.value = !isFilters.value
  }

  function handleSaveFilters () {
    dispatch('save_cases_filters')
  }

  function handleInputFilter ({name, value}) {
    commit('SET_UC_FILTER', {name, value})
  }

  function handleFile ({name, file}) {
    isCSVFile.value = true
    formData = new FormData()
    formData.append(name, file)
    formData.append('type', name)
    commit('SET_UC_FORM_FIELD', {name: 'domain', value: ''})
  }

  function handleClearFile () {
    isCSVFile.value = false
  }
  
  function handleInput ({name, value}) {
    commit('SET_UC_FORM_FIELD', {name, value})
  }
  
  function handleSubmit () {
    if (isCSVFile.value && invalidEmail.value) {
      return
    }
    const action = isCSVFile.value ? 'send_cases_data_csv'  : 'send_cases_data'
    dispatch(action, {formData, clbk: clearFile})
  }
  
  function clearFile () {
    baseFileRef.value.handleClear()
  }

  function isLink(key, value) {
    return key.toLowerCase().includes('link') && value;
  }

  function formatKey(key) {
    return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .use-cases-view {
    @media only screen and (min-width: 0) {
      max-width: 1076px;
      margin: 0 auto 100px;

      h1 + .base-input {
        input {
          width: 424px;
          margin-bottom: 20px;
        }

        label {
          font-weight: 700;
        }
      }

      .result-section {
        h2 {
          font-size: 1.8rem;
          font-weight: 700;
          color: $tech-gray;
        }
        ul {
          margin-top: 20px;
        }
        p {
          font-weight: 400;
          margin: 8px 0 6px;
        }
        a {
          color: $tech-gray;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }

      .filters-section {
        height: 24px;
        transition: height 0.2s ease-in-out;
        overflow: hidden;
        .header {
          h2 + img {
            transform-origin: center;
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;
          }
        }

        hr {
          margin: 25px 0 -20px 0;
          width: 40%;
          color: #7F7F7F;
        }

        p {
          margin-top: 35px;
          margin-bottom: 0;
          font-weight: 400;
          color: $tech-gray;
        }

        .base-input {
          margin-top: 43px;
          display: block;
          input {
            width: 510px;
          }
          span {
            margin-left: 5px;
            color: $tech-gray;
          }
          &.monthsInterval,
          &.viewCountMin,
          &.monthsKeywordInterval {
            input {
              width: 50px;
              text-align: center;
              padding: 15px 0;
            }
          }
        }

        &.active-filters {
          height: 1620px;
        }
      }
    }
  }
</style>