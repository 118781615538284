import axios from "axios";
import api from "@/store/api";

export const state = {
  result: [],
  csvResult: '',
  reqStatus: 'idles',
  form: {
    linkedInUrl: "",
    linkedInToken: localStorage.getItem('linkedInToken')
  },
  filters: {
    dataThresholdYears: '',
    maximumCurrentEmployees: '',
    titlesKeywords: [],
    negativeTitlesKeywords: [],
    minEmployeeCount1: '',
    maxEmployeeCount1: '',
    overlapMonths1: '',
    overlapMonthScore1: '',
    startingDateMonths1: '',
    startingDateMonthsScore1: '',
    similarRolesScore1: '',
    sameLocationScore1: '',
    minEmployeeCount2: '',
    maxEmployeeCount2: '',
    overlapMonths2: '',
    overlapMonthScore2: '',
    startingDateMonths2: '',
    startingDateMonthsScore2: '',
    similarRolesScore2: '',
    sameLocationScore2: '',
  },
  searchName: '',
  errorMsg: ''
}

export const mutations = {
  SET_CW_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_CW_FORM_FIELD (state, {name, value}) {
    state.form[name] = value
  },
  SET_LINKEDIN_TOKEN (state, value) {
    state.form.linkedInToken = value
    localStorage.setItem('linkedInToken', value)
  },
  SET_CW_FILTER (state, {name, value}) {
    switch (name) {
      case 'titlesKeywords':
      case 'negativeTitlesKeywords':
        state.filters[name] = value.split(',')
        break
      default:
        state.filters[name] = value
    }
  },
}

export const actions = {
  async get_co_workers_filters ({commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const {data} = await axios.get(api.co_workers.filters)
      commit('SET_CW_STATE', {key: 'filters', value: data})
      commit('SET_REQ_STATUS', 'idles')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async save_co_workers_filters ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      await axios.put(api.co_workers.filters, state.filters)
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async send_co_workers_data ({state, commit, rootState}) {
    try {
      const formData = new FormData()
      Object.keys(state.form).forEach(key => {
        formData.append(key, state.form[key])
      })
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_CW_STATE', {key: 'result', value: []})
      const {data} = await axios.post(`${api.co_workers.scrape}`, formData)
      commit('SET_CW_STATE', {key: 'searchName', value: state.form.linkedInUrl})
      commit('SET_CW_STATE', {key: 'result', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      commit('SET_CW_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  },
  async send_co_workers_data_csv ({state, commit, rootState}, {formData, clbk = () => {}}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      commit('SET_CW_STATE', {key: 'csvResult', value: ''})
      formData.append('linkedInToken', state.form.linkedInToken)
      formData.append('emailToSendCSV', rootState.emailToSendCSV)
      const {data} = await axios.post(`${api.co_workers.scrape}`, formData)
      commit('SET_CW_STATE', {key: 'csvResult', value: data})
      commit('SET_CW_STATE', {key: 'searchName', value: ''})
      formData.delete('linkedInToken')
      formData.delete('filters')
      formData.delete('emailToSendCSV')
      commit('SET_REQ_STATUS', 'success')
      clbk();
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      formData.delete('linkedInToken')
      formData.delete('filters')
      formData.delete('emailToSendCSV')
      console.log(err)
      commit('SET_CW_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  },
}
