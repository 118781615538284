<template>
  <div class="competitors-targets">
    <h1 class="bold">
      Competitors of Targets <br>
      <span>Find out who are our clients or the competitors of target companies (and who are RS clients)</span>
    </h1>

    <BaseInput name="emailToSendCSV"
               label="Enter the e-mail address you want to receive the results at"
               placeholder="email address to send csv file"
               :value="emailToSendCSV"
               :required="isCSVFile && invalidEmail"
               @input="handleEmail" />

    <div class="search-section flex flex-between align-center">
      <p>
        Who among the competitors of a target, are our clients? Enter a domain or upload a CSV
      </p>
      <BaseInput name="competitors"
                 placeholder="hubspot.com"
                 :disabled="isCSVFile"
                 @input="handleInput" />

      <span class="inline-block">or</span>

      <BaseFileInput name="competitorscsvfile"
                     ref="baseFileRef"
                     label="Upload CSV (with 'domain' column)"
                     @upload="handleFile"
                     @clear="handleClearFile" />


    <button class="purple-btn"
            :disabled="submitDisabled"
            cy="submit"
            @click="handleSubmit">
      Submit
    </button>
  </div>

    <div class="results">
      <div v-show="reqStatus === 'sending'">
        <BaseLoader />
        <p class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>
    </div>

    <div v-if="Object.keys(results).length"
         cy="results"
         class="result-section m-b-24 m-t-24">
      <h2>Results</h2>
      <ul>
        <li v-for="(competitor, index) in results.competitorsData"
            :key="index">
          <p>{{ JSON.stringify(competitor) }}</p>
        </li>
      </ul>
    </div>

    <div v-if="csvResult"
         cy="csv-results"
         class="result-csv">
      <h2>Results for CSV</h2>
      <p>The csv file was sent to email.</p>
    </div>
  </div>
</template>

<script setup>
  import {computed, onBeforeUnmount, ref} from "vue";
  import {useStore} from "vuex";
  import { useValidations } from '@/composables/validations'

  const { state, dispatch, commit } = useStore()
  const results = computed(() => state.competitors.results)
  const csvResult = computed(() => state.competitors.csvResult)
  const competitors = computed(() => state.competitors.competitors)
  const reqStatus = computed(() => state.reqStatus)
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const isCSVFile = ref(false)
  let formData = new FormData()
  const submitDisabled = computed(() => reqStatus.value === 'sending' || (!competitors.value.domain && !isCSVFile.value))
  const baseFileRef = ref(null)
  const { invalidEmail } = useValidations()

  function handleEmail ({name, value}) {
    commit('SET_EMAIL', value)
  }

  function handleFile ({name, file}) {
    isCSVFile.value = true
    formData = new FormData()
    formData.append('csvfile', file)
  }

  function handleClearFile () {
    isCSVFile.value = false;
    formData = new FormData();
  }

  function handleInput ({name, value}) {
    commit('SET_COMPETITORS_DOMAIN', {name, value})
  }

  function handleSubmit () {
    if (isCSVFile.value && invalidEmail.value) {
      return
    }
    commit('RESET_COMPETITORS_STATE')
    const action = isCSVFile.value ? 'query_competitors_csv' : 'query_competitors'
    dispatch(action, {formData, clbk: clearFile})
  }

  function clearFile () {
    baseFileRef.value.handleClear()
  }

  onBeforeUnmount(() => {
    commit('RESET_COMPETITORS_STATE');
  });
</script>

<style lang="scss">
  @import "../styles/vars";

  .competitors-targets {
    @media only screen and (min-width: 0) {
      max-width: 956px;
      margin: 0 auto 100px;

      .search-section {
        p {
          color: $tech-gray;
          font-weight: 700;
        }
      }

      h1 + .base-input {
        input {
          width: 424px;
        }

        label {
          font-weight: 700;
        }
      }

      .purple-btn {
        width: 224px;
      }
    }
  }
</style>