import axios from "axios";
import api from "@/store/api";

export const state = {
  data: {},
  reqStatus: 'idle'
}
export const mutations = {
  SET_STATE(state, { key, value }) {
    state[key] = value;
  },
}
export const actions = {
  async get_video_info ({state, commit}, id) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      const {data} = await axios.get(`${api.youtube.results}/${id}`)
      commit('SET_STATE', {key: 'data', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  }
}